<template>
  <v-app class="bg-login">
    <v-container fluid fill-height>
      <v-layout justify-center>
        <v-flex xs12 sm8 md5 lg4 xl3>
          <v-img
            contain
            class="mt-10 mx-auto d-flex mb-6"
            src="@/assets/img/comprasal_oficial.png"
            max-width="380"
          />

          <v-card class="mt-10 px-2 px-sm-10 elevation-3">
            <v-card-title class="justify-center secondary--text">
              Recuperar Contraseña
            </v-card-title>
            <v-card-text>
              <div class="body-1 my-2">
                Ingrese su correo electrónico para restablecer su contraseña
              </div>
              <v-text-field
                ref="emailInput"
                append-icon="mdi-email"
                name="email"
                label="Correo Electrónico *"
                type="text"
                :error-messages="emailErrors"
                @blur="$v.email.$touch()"
                v-model="email"
                v-if="!show"
              >
              </v-text-field>
              <v-alert
                dense
                text
                type="success"
                v-model="show"
                transition="slide-x-transition"
              >
                Solicitud realizada, por favor revise su bandeja de entrada de
                su
                <strong> correo electrónico</strong>
              </v-alert>
            </v-card-text>
            <v-card-actions
              class="justify-sm-space-around flex-sm-row justify-center flex-column pb-5"
              width="100%"
            >
              <v-btn
                class="primary white--text mb-3 mb-sm-0"
                :loading="loading"
                @click="sendEmail"
              >
                {{ show ? "Reenviar" : "Solicitar" }}
              </v-btn>
              <v-divider vertical></v-divider>
              <v-btn
                class="mt-3 mt-sm-0"
                to="/login"
                color="secondary"
                outlined
              >
                Ir al login
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>

    <v-footer class="primary py-3">
      <v-row>
        <v-col class="d-flex justify-center" cols="12" align-self="center">
          <v-img src="@/assets/img/goes_hrz_blanco.png" max-width="300px" />
        </v-col>
        <!-- <v-col cols="12" sm="6" class="text-sm-end text-center" align-self="center">
          <p class="font-weight-thin white--text no-margin">
            República de El Salvador, C.A.
          </p>
        </v-col> -->
      </v-row>
    </v-footer>
  </v-app>
</template>
<script>
import { required, email } from "vuelidate/lib/validators";

export default {
  name: "recoverPasswordView",
  validations: {
    email: { required, email },
  },
  data: () => ({
    email: null,
    loading: false,
    show: false,
  }),
  methods: {
    async sendEmail() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.loading = true;
          await this.services.Auth.resetPassword({ email: this.email });
          this.show = true;
        } catch {
        } finally {
          this.loading = false;
        }
      }
    },
  },
  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.required && errors.push("El correo es obligatorio");
      !this.$v.email.email && errors.push("El correo no es válido");
      return errors;
    },
  },
  created() {
    this.$refs.emailInput.focus();
  },
};
</script>